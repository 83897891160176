import { Box, BoxProps } from '@chakra-ui/react'
import * as React from 'react'
import { Wrapper } from './wrapper'

export interface HeroProps extends BoxProps {}

export function Hero(props: HeroProps) {
  return (
    <Box
      position={'relative'}
      layerStyle={'primary'}
      bgRepeat={'no-repeat'}
      bgSize={'cover'}
      px={{
        base: 10,
        sm: 15,
        md: 20,
      }}
      pt={{
        base: 70,
        md: 115,
      }}
      pb={{
        base: '60vw',
        md: 60,
      }}
      {...props}
    />
  )
}
