import * as React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { Hero } from '../components/site-only/hero'

// markup
const NotFoundPage = () => {
  return (
    <Box>
      <Hero>
        <Heading size={'md'}>お探しのページが見つかりません。</Heading>
      </Hero>
    </Box>
  )
}

export default NotFoundPage
